import { reactLocalStorage } from 'reactjs-localstorage';
import { checkToken } from './jwtAuth';

const setToken = (idToken) => {
  reactLocalStorage.set('id_token', `Bearer ${idToken}`);
};

const setDataCurrent = (data) => {
  reactLocalStorage.set('data_current', JSON.stringify(data));
};

const getToken = () => reactLocalStorage.get('id_token');

const getDataCurrent = () => {
  if (!reactLocalStorage.get('data_current')) {
    return {};
  }
  return JSON.parse(reactLocalStorage.get('data_current'));
};

const removeToken = () => {
  reactLocalStorage.remove('id_token');
  reactLocalStorage.remove('data_current');
};

const removeAll = () => {
  reactLocalStorage.clear();
};

const isAuth = () => {
  const token = getToken();
  const checkedToken = checkToken(token);
  return checkedToken.isValid && !checkedToken.isExpired;
};

export { setToken, removeAll, getToken, removeToken, isAuth, setDataCurrent, getDataCurrent };
