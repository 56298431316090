import moment from 'moment';
import { NAS_SECRET_KEY, NAS_URL_API, URL_API_AUTH } from 'src/config';
import httpService from 'src/helpers/httpService';
import { sha256 } from 'js-sha256';

const path = 'nas';
export const actionType = (action) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const nas = {
  getLsp: (params) => {
    return (dispatch) => {
      const currentDate = moment().format();
      const nasToken = sha256.hmac(NAS_SECRET_KEY, `GET_${currentDate}`);
      const request = httpService(`bearer ${nasToken}`);
      dispatch({ type: actionType('getLsp').request });
      return request
        .get(`${NAS_URL_API}/lsp`, {
          params,
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
            'X-Lsp-Date': currentDate,
          },
        })
        .then(
          (response) => {
            dispatch({ type: actionType('getLsp').success, payload: response.data });
            return response.data;
          },
          (error) => {
            dispatch({ type: actionType('getLsp').error });
            throw error.response;
          },
        );
    };
  },
  getTuk: (params) => {
    return (dispatch) => {
      const currentDate = moment().format();
      const nasToken = sha256.hmac(NAS_SECRET_KEY, `GET_${currentDate}`);
      const request = httpService(`bearer ${nasToken}`);
      dispatch({ type: actionType('getTuk').request });
      return request
        .get(`${NAS_URL_API}/tuk`, {
          params,
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
            'X-Lsp-Date': currentDate,
          },
        })
        .then(
          (response) => {
            dispatch({ type: actionType('getTuk').success, payload: response.data });
            return response.data;
          },
          (error) => {
            dispatch({ type: actionType('getTuk').error });
            throw error.response;
          },
        );
    };
  },
};
export default nas;
