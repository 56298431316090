import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import app from './reducers/app';
import auth from './reducers/auth';
import invoice from './reducers/invoice';
import product from './reducers/product';
import productSertimedia from './reducers/productSertimedia';
import nas from './reducers/nas';
import user from './reducers/user';
import category from './reducers/category';
import voucher from './reducers/voucher';
import seller from './reducers/seller';
import banner from './reducers/banner';
import article from './reducers/article';
import industry from './reducers/industry';

const reducer = combineReducers({
  form,
  app,
  auth,
  invoice,
  product,
  productSertimedia,
  nas,
  user,
  category,
  voucher,
  seller,
  banner,
  article,
  industry,
});

const store = configureStore({
  reducer,
});

export default store;
