import jwt_decode from 'jwt-decode';
import moment from 'moment';

export const checkToken = (token) => {
  try {
    const decoded = jwt_decode(token);
    const { exp } = decoded;
    if (exp > moment().unix()) {
      return {
        isValid: true,
        expiredTime: exp,
        expiredDate: moment.unix(exp),
        isExpired: false,
      };
    }
    return {
      isValid: true,
      expiredTime: exp,
      expiredDate: moment.unix(exp),
      isExpired: true,
    };
  } catch (e) {
    return {
      isValid: false,
      expiredTime: 0,
      expiredDate: moment(),
      isExpired: true,
    };
  }
};
