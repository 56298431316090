import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../styles/style.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { CSpinner } from '@coreui/react';

const loading = (
  <div className="pt-5 text-center text-primary">
    <CSpinner size="sm" className="me-2" />
    Please wait...
  </div>
);

// Containers
const DashboardLayout = React.lazy(() => import('./DashboardLayout'));

// Pages
const Login = React.lazy(() => import('../pages/auth/login/Login'));
const Register = React.lazy(() => import('../pages/auth/register/Register'));
const Page404 = React.lazy(() => import('../pages/error/Page404'));
const Page500 = React.lazy(() => import('../pages/error/Page500'));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DashboardLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
