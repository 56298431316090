import Swal from 'sweetalert2';

export const alertSuccess = (text) => {
  return Swal.fire({
    html: `
    <div style="display: flex; align-items: center; margin-top: 1rem;">
      <img src="/icons/check-circle.svg" width="35" style="margin-right: 1rem;" />
      ${text || 'Sukses'}
    </div>
    `,
    confirmButtonColor: '#1A61CD',
    confirmButtonText: 'Oke',
  });
};
export const alertError = (text) => {
  return Swal.fire({
    html: `
    <div style="display: flex; align-items: center; margin-top: 1rem;">
      <img src="/icons/x-circle.svg" width="35" style="margin-right: 1rem;" />
      <span style="text-align: left;">${text || 'Error'}</span>
    </div>
    `,
    confirmButtonColor: '#1A61CD',
    confirmButtonText: 'Oke',
  });
};
export const alertWarning = (text) => {
  return Swal.fire({
    html: `
    <div style="display: flex; align-items: center; margin-top: 1rem;">
      <img src="/icons/warning.svg" width="35" style="margin-right: 1rem;" />
      ${text || 'Warning'}
    </div>
    `,
    confirmButtonColor: '#1A61CD',
    confirmButtonText: 'Oke',
  });
};
