import { URL_API_AUTH } from 'src/config';
import httpService from 'src/helpers/httpService';
import baseActions from '../baseActions';

const path = 'banner-publics';
export const actionType = (action) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const banner = {
  ...baseActions(URL_API_AUTH, path, actionType),
  updateData: (data) => {
    return (dispatch) => {
      const http = httpService();
      dispatch({ type: actionType('update').request });
      return http.put(`${URL_API_AUTH}/${path}`, data).then(
        (response) => {
          dispatch({ type: actionType('update').success });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('update').error });
          throw error.response;
        },
      );
    };
  },
};
export default banner;
