import { URL_API_AUTH } from 'src/config';
import httpService from 'src/helpers/httpService';
import baseActions from '../baseActions';

const path = 'seller';
export const actionType = (action) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const seller = {
  ...baseActions(URL_API_AUTH, path, actionType),
  fetchAll: (params) => {
    const http = httpService();
    return (dispatch) => {
      dispatch({ type: actionType('fetchAll').request });
      return http.get(`${URL_API_AUTH}/sellers`, { params: { ...params } }).then(
        (response) => {
          dispatch({ type: actionType('fetchAll').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchAll').error });
          throw error.response;
        },
      );
    };
  },
};
export default seller;
