import { URL_API_PRODUCT } from 'src/config';
import httpService from 'src/helpers/httpService';
import baseActions from '../baseActions';

const path = 'product-certifications';
export const actionType = (action) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const productSertimedia = {
  fetchList: baseActions(URL_API_PRODUCT, path, actionType).fetchList,
  fetchDetail: (id, params) => {
    return (dispatch) => {
      const http = httpService();
      dispatch({ type: actionType('fetchDetail').request });
      return http.get(`${URL_API_PRODUCT}/public/product-certifications/${id}`, { params }).then(
        (response) => {
          dispatch({ type: actionType('fetchDetail').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchDetail').error });
          throw error.response;
        },
      );
    };
  },
};
export default productSertimedia;
