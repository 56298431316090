const baseInitState = {
  isLoading: false,
  isLoadingDetail: false,
  isLoadingSubmit: false,
  isLoadingDelete: false,
  dataAll: [],
  dataList: [],
  dataDetail: null,
  pagination: {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    recordPerPage: 10,
  },
  params: {
    page: 1,
    limit: 10,
    sortBy: 'created_at',
    order: 'desc',
  },
};
export default baseInitState;
