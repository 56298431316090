import httpService from 'src/helpers/httpService';

const baseActions = (baseUrl, path, actionType) => {
  const http = httpService();
  return {
    fetchList: (params) => {
      return (dispatch) => {
        dispatch({ type: actionType('fetchList').request });
        return http.get(`${baseUrl}/${path}`, { params }).then(
          (response) => {
            dispatch({ type: actionType('fetchList').success, response: response.data, params });
            return response.data;
          },
          (error) => {
            dispatch({ type: actionType('fetchList').error });
            throw error.response;
          },
        );
      };
    },

    fetchAll: (params) => {
      return (dispatch) => {
        dispatch({ type: actionType('fetchAll').request });
        return http.get(`${baseUrl}/${path}/all`, { params: { ...params, status: true } }).then(
          (response) => {
            dispatch({ type: actionType('fetchAll').success, response: response.data });
            return response.data;
          },
          (error) => {
            dispatch({ type: actionType('fetchAll').error });
            throw error.response;
          },
        );
      };
    },

    fetchDetail: (id, params) => {
      return (dispatch) => {
        dispatch({ type: actionType('fetchDetail').request });
        return http.get(`${baseUrl}/${path}/${id}`, { params }).then(
          (response) => {
            dispatch({ type: actionType('fetchDetail').success, response: response.data });
            return response.data;
          },
          (error) => {
            dispatch({ type: actionType('fetchDetail').error });
            throw error.response;
          },
        );
      };
    },

    createData: (data) => {
      return (dispatch) => {
        dispatch({ type: actionType('create').request });
        return http.post(`${baseUrl}/${path}`, data).then(
          (response) => {
            dispatch({ type: actionType('create').success });
            return response.data;
          },
          (error) => {
            dispatch({ type: actionType('create').error });
            throw error.response;
          },
        );
      };
    },

    updateData: (data) => {
      return (dispatch) => {
        dispatch({ type: actionType('update').request });
        return http.put(`${baseUrl}/${path}`, data).then(
          (response) => {
            dispatch({ type: actionType('update').success });
            return response.data;
          },
          (error) => {
            dispatch({ type: actionType('update').error });
            throw error.response;
          },
        );
      };
    },

    deleteData: (id) => {
      return (dispatch) => {
        dispatch({ type: actionType('delete').request });
        return http.delete(`${baseUrl}/${path}/${id}`).then(
          (response) => {
            dispatch({ type: actionType('delete').success });
            return response.data;
          },
          (error) => {
            dispatch({ type: actionType('delete').error });
            throw error.response;
          },
        );
      };
    },

    resetParams: () => (dispatch) => {
      dispatch({ type: actionType('resetParams').success });
    },

    resetDetail: () => (dispatch) => {
      dispatch({ type: actionType('resetDetail').success });
    },
  };
};
export default baseActions;
