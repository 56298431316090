import { actionType } from '../actions/industry';
import baseInitState from '../baseInitState';
import baseReducer from '../baseReducer';

const initState = {
  ...baseInitState,
};

export default function industry(state = initState, action) {
  switch (action.type) {
    default:
      return baseReducer(state, action, actionType);
  }
}
