import { URL_API_ORDER } from 'src/config';
import httpService from 'src/helpers/httpService';
import baseActions from '../baseActions';

const path = 'invoice';
export const actionType = (action) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const http = httpService();
const invoice = {
  ...baseActions(URL_API_ORDER, path, actionType),
  fetchList: (params) => {
    return (dispatch) => {
      dispatch({ type: actionType('fetchList').request });
      return http.get(`${URL_API_ORDER}/invoice/list/admin`, { params }).then(
        (response) => {
          dispatch({ type: actionType('fetchList').success, response: response.data, params });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchList').error });
          throw error.response;
        },
      );
    };
  },
  confirmPayment: (data) => {
    return http.post(`${URL_API_ORDER}/payments`, data).then(
      (response) => {
        return response.data;
      },
      (error) => {
        throw error.response;
      },
    );
  },
};
export default invoice;
