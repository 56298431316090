import { URL_API_AUTH } from 'src/config';
import httpService from 'src/helpers/httpService';
import { removeAll, setDataCurrent, setToken } from 'src/helpers/token';
import jwt_decode from 'jwt-decode';

const path = 'login';
export const actionType = (action) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const request = httpService();
const auth = {
  login: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('login').request });
      return request.post(`${URL_API_AUTH}/login`, data).then(
        (response) => {
          dispatch({ type: actionType('login').success, payload: response.data?.data });
          let dataCurrent = jwt_decode(response.data?.data?.access_token);
          if (dataCurrent?.role_code !== 'ADMIN') {
            throw { status: 403, role: dataCurrent?.role_code };
          }
          setToken(response.data?.data?.access_token);
          setDataCurrent(dataCurrent);
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('login').error });
          throw error.response;
        },
      );
    };
  },
  confirmPassword: (data) => {
    return request.post(`${URL_API_AUTH}/check-password`, data).then(
      (response) => {
        return response.data;
      },
      (error) => {
        throw error.response;
      },
    );
  },
  logout: () => {
    removeAll();
    window.location.href = '/login';
  },
};
export default auth;
