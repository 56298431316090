import { URL_API_PRODUCT } from 'src/config';
import baseActions from '../baseActions';

const path = 'public/products';
export const actionType = (action) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const product = {
  ...baseActions(URL_API_PRODUCT, path, actionType),
};
export default product;
