import { actionType } from '../actions/nas';

const initState = {
  isLoading: false,
  lsp: {},
  tuk: {},
};

export default function nas(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionType('getLsp').request:
      return {
        ...state,
        isLoading: true,
      };
    case actionType('getLsp').success:
      return {
        ...state,
        lsp: payload,
        isLoading: false,
      };
    case actionType('getLsp').error:
      return {
        ...state,
        isLoading: false,
      };
    case actionType('getTuk').request:
      return {
        ...state,
        isLoading: true,
      };
    case actionType('getTuk').success:
      return {
        ...state,
        tuk: payload,
        isLoading: false,
      };
    case actionType('getTuk').error:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
